import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import ThemeContext from "../../context/ThemeContext"
import { graphql, Link } from "gatsby"
import ContentWrapper from "../../components/text-helpers/content-wrapper"
import SEO from "../../components/seo"
import t from '../../services/GetTranslationValue'
import r from '../../services/Router'
import FreespinsSearch from "../../components/main-search/freespins-search"
import Img from "gatsby-image"
import Rating from "@material-ui/lab/Rating"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import detailStyles from "../game-providers/item.module.scss"
import styles from "./slot.module.scss"
import localize from "../../components/localize"


const Details = ({ translations, locale, game}) => {
  
  const gameTypes = [
    ["freeSpin", ''],
    ["multipler", ''], 
    ["progressive", ''], 
    ["has3d", ''],
    ["scatterSymbol", ''],
    ["wildSymbol", ''],
    ["autoplayOption", ''],
    ["bonusGame", ''],
  ];

  const getItemIcons = (game, x) => {
    return [game[x[0]] ?
      <img alt={`${game.name} ${t.get(translations, `game-details-${x[0].replace(/[A-Z]/, "-$&").toLowerCase()}`)}`} src={`/../../../icons/game-provider/normal/${x[0]}.svg`} />
      :
      <img alt={`${game.name} ${t.get(translations, `game-details-${x[0].replace(/[A-Z]/, "-$&").toLowerCase()}`)}`} src={`/../../../icons/game-provider/disabled/${x[0]}.svg`} />
      ,
    <span className={`${game[x[0]] ? "" : detailStyles.notAvailable}`}>
      {t.get(translations, `game-details-${x[0].replace(/[A-Z]/, "-$&").toLowerCase()}`)}
    </span>]
  }

  return (
    <section className={`${detailStyles.detailsContainer}`}>
      <div className={`${detailStyles.info}`}>
        <div className={`${detailStyles.logoContainer}`}>
          { game.logoImage ? <Img
            alt={game.name}
            fluid={game.logoImage.asset.fluid}
            className={`${detailStyles.logo}`}
          >
            {" "}
            {game.name}{" "}
          </Img> : '' }
        </div>
        <div className={`${detailStyles.infoContainer}`}>
          <div className={`${detailStyles.nameContainer}`}>
            <p className={`${detailStyles.name}`}>{game.name}</p>
            <p className={`${detailStyles.rating}`}>
              <Rating
                readOnly={true}
                name="half-rating"
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                size="small"
                value={0}
                precision={0.5}
              />
            </p>
          </div>
          <div className={`${detailStyles.rowContainer}`}>
            <p className={`${detailStyles.infoRow}`}>
              <label>{t.get(translations, 'game-details-game-provider')}: </label>
              {game.gameProvider && <Link to={r.get(locale, translations, "gameProvider", game.gameProvider.name)}>
                <span>{game.gameProvider.name}</span>
              </Link>}
            </p>
            <p className={`${detailStyles.infoRow}`}>
              <label>{t.get(translations, 'game-details-release-date')}: </label><span>{game.releaseDate}</span>
            </p>
          </div>
        </div>
        <div className={`${styles.specifications}`}>
          <div className={`${styles.item}`}>
            <img alt={`${game.name} ${t.get(translations, 'game-details-jackpot')}`} src={`/../../../icons/game-provider/normal/jackpot.svg`} />
            <span className={`${styles.value}`}>
              {game.jackpot}
            </span>
            <span className={`${styles.name}`}>
              {t.get(translations, 'game-details-jackpot')}
            </span>
          </div>
          <div className={`${styles.item}`}>
            <img alt={`${game.name} ${t.get(translations, 'game-details-playlines')}`} src={`/../../../icons/game-provider/normal/paylines.svg`} />
            <span className={`${styles.value}`}>
              {game.paylines}
            </span>
            <span className={`${styles.name}`}>
              {t.get(translations, 'game-details-playlines')}
            </span>
          </div>
          <div className={`${styles.item}`}>
            <img alt={`${game.name} ${t.get(translations, 'game-details-reels')}`} src={`/../../../icons/game-provider/normal/reels.svg`} />
            <span className={`${styles.value}`}>
              {game.reels}
            </span>
            <span className={`${styles.name}`}>
              {t.get(translations, 'game-details-reels')}
            </span>
          </div>
          <div className={`${styles.item}`}>
            <img alt={`${game.name} ${t.get(translations, 'game-details-coins-per-line')}`} src={`/../../../icons/game-provider/normal/coins-per-line.svg`} />
            <span className={`${styles.value}`}>
              {game.minCoinsPerLine}-{game.maxCoinsPerLine}
            </span>
            <span className={`${styles.name}`}>
              {t.get(translations, 'game-details-coins-per-line')}
            </span>
          </div>
          <div className={`${styles.item}`}>
            <img alt={`${game.name} ${t.get(translations, 'game-details-coin-size')}`} src={`/../../../icons/game-provider/normal/coin-size.svg`} />
            <span className={`${styles.value}`}>
              {game.minimumCoinSize}-{game.maximumCoinSize}
            </span>
            <span className={`${styles.name}`}>
              {t.get(translations, 'game-details-coin-size')}
            </span>
          </div>
          <div className={`${styles.item}`}>
            <img alt={`${game.name} ${t.get(translations, 'game-details-rtp')}`} src={`/../../../icons/game-provider/normal/rtp.svg`} />
            <span className={`${styles.value}`}>
              {game.rtp}
            </span>
            <span className={`${styles.name}`}>
              {t.get(translations, 'game-details-rtp')}
            </span>
          </div>
        </div>
      </div>
      <div className={`${detailStyles.games}`}>
        <p className={`${detailStyles.title}`}>{t.get(translations, 'game-details-features')}</p>
        <div className={`${detailStyles.gamesParent}`}>
          {gameTypes.map(x =>
            <div className={`${detailStyles.game}`}>
              {NullIf.isNotNullOrEmpty(x[1]) ?
                <Link to={r.get(locale, translations, x[1], '')}>
                  {getItemIcons(game, x)}
                </Link>
                : getItemIcons(game, x)
              }

            </div>
          )}
        </div>
      </div>
    </section>
  )
}

const Content = ({ bonuses, slotName, locale }) => {

  return (
    <div>
      <FreespinsSearch bonuses={bonuses} relatedGame={slotName} locale={locale} />
    </div>
  )
}

const ASlot = ({ pageContext, location, data }) => {
  let site = useContext(ThemeContext)
  let { slotName, locale, alternateLocaleSlugs } = pageContext
  let translations = site.translations;
  let game = data.sanityGame
  
  let bonuses = site.bonuses
  bonuses = bonuses
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => NullIf.isNotNullOrEmpty(x.bonuses))
    .filter(x => NullIf.isNotNullOrEmpty(x.logo))
    .filter(x => {
      let games = [].concat.apply([], x.bonuses)
      if (!NullIf.isNotNullOrEmpty(games)) return false
      // we have an array of casino bonuses now
      games = games.filter(y => NullIf.isNotNullOrEmpty(y.relatedGames))

      return games.some(y =>
        y.relatedGames.some(
          z => NullIf.isNotNullOrEmpty(z.name) && z.name === slotName
        )
      )
    })

  return (
    <Layout silo={data.sanitySilo} pageContext={pageContext} location={location} bcDark>
      <SEO location={location}
           alternateSlug={alternateLocaleSlugs}
           title={game.seoTitle ? game.seoTitle : game.name + ' ' + t.get(translations, 'game-default-title')} description={game.seoMeta} />
      <Details
        translations={translations}
        locale={locale}
        game={game}
      />
      <ContentWrapper
        title={game._rawHeaderContent ? null : game.name}
        footer={game._rawFooterContent}
        body={game._rawContent}
        header={game._rawHeaderContent}
      >
        <Content bonuses={bonuses} slotName={slotName} locale={locale} />
      </ContentWrapper>
    </Layout>
  )
}
export default localize(ASlot)
export const query = graphql`
  query SlotQuery($slotName: String!) {
      sanitySilo (_id: {eq: "2490d32a-1555-42d2-aab6-970ff9702841"}){
          _id
          breadcrumbTrail {
              ...LocaleSiloTrails
          }
      }
    sanityGame(name: { eq: $slotName }) {
      ...GameFull
    }
  }
`
